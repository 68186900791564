import React, { createContext, useEffect, useMemo, useState } from "react";

import TutorList from "./TutorList";
import { useTutorQuery } from "./query";
import SearchTutors from "./SearchTutors";

export const AssignTutorContext = createContext();

const AssignTutorModal = () => {
  const [tutors, setTutors] = useState([]);
  const [lastName, setLastName] = useState("");
  const query = useTutorQuery({lastName});
  const value = useMemo(() => ({
    lastName,
    tutors,
    setTutors,
    setLastName,
  }), [lastName, tutors, setLastName, setTutors]);

  useEffect(() => {
    if (query.data?.tutors) {
      setTutors(query.data.tutors);
    }
  }, [query.data?.tutors]);

  return (
    <AssignTutorContext.Provider value={value}>
      <div className="w-full">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">Search Tutors</h1>
          <p className="mt-2 text-sm text-gray-700">Search Tutors to claim the session</p>
        </div>
        <div className="mt-6">
          <SearchTutors />
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <TutorList />
            </div>
          </div>
        </div>
      </div>
    </AssignTutorContext.Provider>
  );
}

export default AssignTutorModal;
