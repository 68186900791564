import { useMutation, useQuery } from "@tanstack/react-query";

import { axiosDelete, get, post } from "../../api";
import { timeTo24HourFormat } from "../../utils";

export const useCancelTimeOffMutation = (selectedTimeOff) => {
  return useMutation({
    mutationFn: async () => await axiosDelete(`tutor_time_offs/${selectedTimeOff}`),
  });
};

export const useTutoringBlocksQuery = (startDate) => {
  return useQuery({
    queryKey: ["tutoring-blocks", startDate],
    queryFn: async ({ signal }) => {
      const res = await get(`tutor_time_off/tutoring_blocks`, {
        params: {
          start_date: startDate,
        },
        signal,
      });
      return res.data;
    },
    enabled: !!startDate,
  });
};

export const useTutorTimeOffMutation = ({ startDate, endDate, reason, selectedSession }) => {
  const newSession = selectedSession.length
    ? {
        [startDate]: selectedSession.map((session) => timeTo24HourFormat(session.value)),
      }
    : {};

  return useMutation({
    mutationFn: async () =>
      await post("tutor_time_off", {
        tutor_time_off: {
          start_date: startDate,
          end_date: endDate,
          reason,
          selectedSession: newSession,
        },
      }),
  });
};
