import React, { useContext } from "react";

import FilterDropdown from "../common/FilterDropdown";
import { FilterContext, SchoolsContext, SchoolSystemsContext } from "../../contexts/MasterCalendar";
import ReasonPill from "./ReasonPill";
import { cancellationReason } from "../../constants";

export default () => {
  const {
    nonInstructionalDayReasonsFilter,
    schoolsFilter,
    setNonInstructionalDayReasonsFilter,
    setSchoolsFilter,
  } = useContext(FilterContext);
  const { schoolSystems } = useContext(SchoolSystemsContext);
  const { schools } = useContext(SchoolsContext);
  const onChangeSchoolsFilter = (values) => {
    setSchoolsFilter(values);
  };
  const onChangeNonInstructionalDayReasonsFilter = (values) => {
    setNonInstructionalDayReasonsFilter(values);
  };

  return [
    <FilterDropdown
      activeFilterCount={schoolsFilter.length}
      className="w-[340px]"
      key="schools"
      label="Schools"
      onChange={onChangeSchoolsFilter}
      options={schoolSystems.map(schoolSystem => ({
        label: schoolSystem.name,
        options: schools.filter(school => school.schoolSystemId === schoolSystem.id).map(school => ({
          value: school.id,
          label: school.name,
        })),
      }))}
      searchable
      searchInputPlaceholder="Search for schools or school systems..."
    />,
    <FilterDropdown
      activeFilterCount={nonInstructionalDayReasonsFilter.length}
      key="reason"
      label="Reason"
      onChange={onChangeNonInstructionalDayReasonsFilter}
      options={[
        {
          value: "no_program",
          label: <ReasonPill className="bg-zinc-100 inline-flex" reason={cancellationReason.NO_PROGRAM} />
        },
        {
          value: "last_minute_cancellations",
          label: <ReasonPill className="bg-zinc-100 inline-flex" reason={cancellationReason.LAST_MINUTE} />
        },
      ]}
    />
  ];
}
