import React, { useContext, useEffect } from "react";

import { useTutorTimeOffQuery } from "./queries";
import TimeOffItem from "./TimeOffItem";
import { Table } from "../common/Table";
import { APPROVED, DENIED, PENDING } from "../../constants/TutorTimeOffStatus";
import { PagyContext } from "../common/Pagy";
import { PAGY_UPPER_PAGE_BOUND } from "../../constants";
import TutorTimeOffRequestsContext from "./context";

const statusToLabelMap = {
  [APPROVED]: "approved",
  [DENIED]: "denied",
};

const TimeOffList = ({ "data-testid": dataTestid, status }) => {
  const query = useTutorTimeOffQuery({ status });
  const { setPagy, setUpperPageBound, setLowerPageBound } = useContext(PagyContext);
  const { filterEndDate, filterStartDate, searchTerm } = useContext(TutorTimeOffRequestsContext);

  useEffect(() => {
    setUpperPageBound(PAGY_UPPER_PAGE_BOUND);
    setLowerPageBound(0);
    setPagy((prevPagy) => ({ ...prevPagy, page: 1 }));
  }, [setUpperPageBound, setLowerPageBound, setPagy, filterStartDate, filterEndDate, searchTerm]);

  return (
    <Table
      columns={[
        {
          id: "first_name",
          label: "First Name",
          isSortable: true,
        },
        {
          id: "last_name",
          label: "Last Name",
          isSortable: true,
        },
        {
          id: "start_date",
          label: "Time Off Requested",
          isSortable: true,
          colSpan: 2,
        },
      ]}
      data-testid={dataTestid}
      defaultSortKey="start_date"
      query={query}
    >
      {(data) => data.tutorTimeOff.length > 0 ? data.tutorTimeOff.map(tutorTimeOff => (
        <TimeOffItem key={tutorTimeOff.uuid} {...tutorTimeOff} />
      )) : (
        <tr>
          <td
            className="bg-white p-4 text-sm text-gray-900 sm:pl-6 rounded-b-lg"
            colSpan={4}
          >
            <p className="flex items-center justify-center grow text-gray-500 min-h-[120px]">
              {searchTerm
                ? `There are no results for: ${searchTerm}`
                : status === PENDING
                  ? "There are no time off requests to review."
                  : `There are no ${statusToLabelMap[status]} time off requests.`}
            </p>
          </td>
        </tr>
      )}
    </Table>
  );
};

export default TimeOffList;
