import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "../common/Tabs";

import ChangesList from "./ChangesList";
import { PagyProvider } from "../common/Pagy";
import Filters from "./Filters";

export default () => [
  <div className="bg-white py-6" key="header">
    <div className="sm:flex sm:items-center sm:justify-between">
      <h1 className="text-2xl font-semibold text-gray-900">Session Changes Queue</h1>
      <p className="flex items-centerZ text-zinc-500 text-xs mr-5 pt-0.5 whitespace-nowrap">
        <em>All times are in the school&apos;s time zone</em>
      </p>
    </div>
  </div>,
  <div className="flex items-center" key="filters">
    <Filters />
  </div>,
  <Tabs className="grow" key="content">
    <TabList>
      <Tab>To do</Tab>
      <Tab>Completed</Tab>
    </TabList>
    <TabPanel className="grow flex flex-col">
      <PagyProvider>
        <ChangesList done={false} />
      </PagyProvider>
    </TabPanel>
    <TabPanel className="grow flex flex-col">
      <PagyProvider>
        <ChangesList done />
      </PagyProvider>
    </TabPanel>
  </Tabs>
];
