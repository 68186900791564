import React from "react";
import { toast } from "react-toastify";

import { useUpdateTimeOffMutation } from "./queries";
import { APPROVED, DENIED } from "../../constants/TutorTimeOffStatus";

const TimeOffItemButtons = ({ firstName, lastName, status, uri, uuid }) => {
  const mutation = useUpdateTimeOffMutation({ status, uri });
  const onApprove = () => {
    mutation.mutate({ status: APPROVED }, {
      onSuccess: () => {
        toast(<>Time off request for <strong data-heap-redact-text>{firstName} {lastName}</strong> approved.</>, {
          autoClose: 3200,
          isLoading: false,
        });
      },
      onError: () => {
        toast("There was a problem approving time off. Please try again.", {
          autoClose: 3200,
          isLoading: false,
          type: "error",
        });
      },
    });
  };
  const onDeny = () => {
    mutation.mutate({ status: DENIED }, {
      onSuccess: () => {
        toast(<>Time off request for <strong data-heap-redact-text>{firstName} {lastName}</strong> denied.</>, {
          autoClose: 3200,
          isLoading: false,
        });
      },
      onError: () => {
        toast("There was a problem denying time off. Please try again.", {
          autoClose: 3200,
          isLoading: false,
          type: "error",
        });
      },
    });
  };

  return [
    <button
      className="button-primary w-[100px]"
      data-testid={`time-off-request-${uuid}-approve-button`}
      disabled={mutation.isLoading || status === APPROVED}
      key="approve"
      onClick={onApprove}
      type="button"
    >
      {status === APPROVED ? "Approved" : "Approve"}
    </button>,
    <button
      className="button-secondary w-[100px]"
      data-testid={`time-off-request-${uuid}-deny-button`}
      disabled={mutation.isLoading || status === DENIED}
      key="deny"
      onClick={onDeny}
      type="button"
    >
      {status === DENIED ? "Denied" : "Deny"}
    </button>
  ];
};

export default TimeOffItemButtons;
