import cx from "classnames";
import React, { useId } from "react";

export default ({
  options: groupedOptions,
  onChange,
  onSelectAll,
  onSelectInverse,
  onSelectNone,
  searchValue,
  selectedValues,
  type,
}) => {
  const id = useId();

  return (
    <ul className="overflow-scroll grow">
      {groupedOptions.map((groupedOption) => {
        const values = groupedOption.options.map((option) => option.value.toString());
        const hasAllValuesSelected = values.every((value) => selectedValues.includes(value));
        const hasSomeValuesSelected = values.some((value) => selectedValues.includes(value));
        const searchResults = groupedOption.label.toLowerCase().includes(searchValue)
          ? groupedOption.options
          : groupedOption.options.filter((option) =>
              option.label.toLowerCase().includes(searchValue)
            );

        return searchResults.length > 0 ? (
          <li
            className="flex flex-col group"
            key={groupedOption.label}
            data-testid="grouped-option-list"
          >
            <div
              className="bg-zinc-50 border-b border-zinc-200/60 flex items-center justify-between text-xs sticky top-0 px-3 py-1"
              data-testid="grouped-option-label"
            >
              <span className="text-zinc-500 font-medium truncate pr-2">{groupedOption.label}</span>
              <div className="flex flex-nowrap space-x-1.5">
                <span className="text-zinc-400">Select:</span>
                {!hasSomeValuesSelected || (hasSomeValuesSelected && !hasAllValuesSelected) ? (
                  <button
                    className="shrink-0 text-brand-500 text-xs leading-none hover:underline"
                    onClick={onSelectAll(values)}
                    type="button"
                  >
                    All
                  </button>
                ) : null}
                {hasSomeValuesSelected && !hasAllValuesSelected ? (
                  <button
                    className="shrink-0 text-brand-500 text-xs leading-none hover:underline"
                    onClick={onSelectInverse(values)}
                    type="button"
                  >
                    Invert
                  </button>
                ) : null}
                {hasSomeValuesSelected ? (
                  <button
                    className="shrink-0 text-brand-500 text-xs leading-none hover:underline"
                    onClick={onSelectNone(values)}
                    type="button"
                  >
                    None
                  </button>
                ) : null}
              </div>
            </div>
            <ul className="border-b border-zinc-200/60 group-last:border-b-0">
              {searchResults.map((option) => (
                <li
                  className="flex items-center px-3 hover:bg-zinc-100/70"
                  key={option.value}
                  data-testid="option-list"
                >
                  <input
                    checked={selectedValues.includes(option.value.toString())}
                    className={cx({
                      checkbox: type === "checkbox",
                      radio: type === "radio",
                    })}
                    id={`${id}-${option.value}`}
                    name={option.name}
                    onChange={onChange}
                    type={type}
                    value={option.value}
                  />
                  <label
                    className="cursor-pointer grow py-3 px-3 text-sm text-zinc-500"
                    htmlFor={`${id}-${option.value}`}
                  >
                    {option.label}
                  </label>
                </li>
              ))}
            </ul>
          </li>
        ) : null;
      })}
    </ul>
  );
};
