import { useQuery } from "@tanstack/react-query";
import { eachWeekOfInterval, endOfMonth, endOfWeek, format, startOfMonth } from "date-fns";
import first from "lodash/first";
import last from "lodash/last";
import React, { useContext, useEffect } from "react";

import { get } from "../../api";
import { CALENDAR_MONTLY_VIEW, CALENDAR_WEEKLY_VIEW, DEFAULT_DATE_FORMAT } from "../../constants";
import { CurrentDateContext, CurrentViewContext } from "../../contexts/Calendar";
import {
  CalendarEventsContext,
  FilterContext,
  SchoolsContext,
  SchoolSystemsContext
} from "../../contexts/MasterCalendar";
import DailyView from "./DailyView";
import Header from "./Header";
import MonthlyView from "./MonthlyView";
import WeeklyView from "./WeeklyView"

export default () => {
  const { currentDate } = useContext(CurrentDateContext);
  const { currentView } = useContext(CurrentViewContext);
  const { setCalendarEvents } = useContext(CalendarEventsContext);
  const { nonInstructionalDayReasonsFilter, schoolsFilter } = useContext(FilterContext);
  const { setSchools } = useContext(SchoolsContext);
  const { setSchoolSystems } = useContext(SchoolSystemsContext);
  const weeks = eachWeekOfInterval({
    start: startOfMonth(currentDate),
    end: endOfMonth(currentDate),
  }, { weekStartsOn: 1 });
  const calendarQuery = useQuery({
    queryKey: ["calendar", currentDate, nonInstructionalDayReasonsFilter, schoolsFilter],
    queryFn: async ({ signal }) => {
      const { data } = await get("master_calendar.json", {
        params: {
          end_date: format(endOfWeek(last(weeks)), DEFAULT_DATE_FORMAT),
          non_instructional_day_reasons: nonInstructionalDayReasonsFilter,
          schools: schoolsFilter,
          start_date: format(first(weeks), DEFAULT_DATE_FORMAT),
        },
        signal,
      });

      return data;
    },
    keepPreviousData: true,
    staleTime: 5000,
  });
  const CurrentView = currentView === CALENDAR_MONTLY_VIEW
    ? MonthlyView
    : currentView === CALENDAR_WEEKLY_VIEW
      ? WeeklyView
      : DailyView;

  useEffect(() => {
    if (calendarQuery.isFetched) {
      const { calendarEvents, schoolSystems, schools } = calendarQuery.data;

      setCalendarEvents(calendarEvents);
      setSchoolSystems(schoolSystems);
      setSchools(schools);
    }
  }, [
    calendarQuery.data,
    calendarQuery.isFetched,
    setCalendarEvents,
    setSchoolSystems,
    setSchools,
  ]);

  return [
    <Header key="header" />,
    <CurrentView key="content" />,
  ];
}
