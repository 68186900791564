import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";

import { Tooltip, TooltipContent, TooltipTrigger } from "./Tooltip";

const RESET_COPIED_STATE_TIMEOUT = 1400;

export default ({ children, className, text }) => {
  const timerRef = useRef(null);
  const [isCopied, setIsCopied] = useState(false);
  const onClick = () => {
    navigator.clipboard.writeText(text).then(() => {
      setIsCopied(true);
      timerRef.current = setTimeout(() => {
        setIsCopied(false);
      }, RESET_COPIED_STATE_TIMEOUT);
    });
  };
  const onAfterOpenChange = (isOpen) => {
    if (!isOpen) {
      clearTimeout(timerRef.current);
      setIsCopied(false);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    }
  }, []);

  return (
    <Tooltip onAfterOpenChange={onAfterOpenChange}>
      <TooltipTrigger>
        <button
          className={cx(className)}
          onClick={onClick}
          type="button"
        >
          {children}
        </button>
      </TooltipTrigger>
      <TooltipContent className="py-1.5 px-2" dark>
        <div className="text-xs h-3.5 leading-none flex items-center">
          {isCopied ? (
            <span className="flex items-center gap-1">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="text-[#80AD1F] w-3.5 h-3.5">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
              </svg>
              Copied
            </span>
          ) : "Copy to clipboard"}
        </div>
      </TooltipContent>
    </Tooltip>
  );
};
