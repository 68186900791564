import React, { useContext, useState } from "react";

import { AssignTutorContext } from "./AssignTutorModal";

const SearchTutors = () => {
  const [name, setName] = useState("");
  const { setLastName } = useContext(AssignTutorContext);

  const onSubmit = (e) => {
    e.preventDefault();
    setLastName(name);
  }

  return (
    <form onSubmit={onSubmit} data-testid="admin-sub-session-request-search-tutors-form">
      <div className="mt-6 flex space-x-4">
        <div className="min-w-0 flex-1">
          <div className="relative rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd" />
              </svg>
            </div>
            <input
              type="search"
              name="q"
              id="q"
              autoComplete="off"
              className="pl-10 focus:ring-brand-500 focus:border-brand-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              placeholder="Search by last name"
              value={name}
              onChange={(e)=>setName(e.target.value)}
            />
          </div>
        </div>
        <button
          type="submit"
          className="cursor-pointer py-0 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
        >
          Search
        </button>
      </div>
    </form>
  );
}

export default SearchTutors;
