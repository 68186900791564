import React, { useContext } from "react";

import TutorItem from "./TutorItem";
import { AssignTutorContext } from "./AssignTutorModal";

const TutorList = () => {
  const { tutors } = useContext(AssignTutorContext);

  return (
    <table className="min-w-full divide-y divide-gray-300 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg" data-testid="admin-sub-session-request-tutor-table">
      <thead>
        <tr>
          <th scope="col" className="bg-gray-50 rounded-tl-lg py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Tutor Name</th>
          <th scope="col" className="bg-gray-50 rounded-tr-lg relative py-3.5 pl-3 pr-4 sm:pr-6"></th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {tutors.length ? (
          tutors.map((tutor) => (
            <TutorItem key={tutor.uuid} tutor={tutor} />
          ))
         ) : (
          <tr>
            <td className="bg-white p-4 text-sm text-gray-900 text-center sm:pl-6 rounded-b-lg" colSpan={2}>
              No tutors found
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default TutorList;
