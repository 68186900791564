import React, { useMemo } from "react";

import FilterDropdown from "../common/FilterDropdown";
import FilterShortcutCheckbox from "../common/FilterShortcutCheckbox";

const SCHOOL_FILTER_PARAM_KEY = "filter[school][]";
const AUTO_SCHEDULING_FILTER_PARAM_KEY = "filter[auto_scheduling]";

export default (props) => {
  const schoolSystems = props.schoolSystems.map(([id, name]) => ({ id, name }));
  const schools = props.schools.map(([id, name, schoolSystemId]) => ({ id, name, schoolSystemId }));
  const currentUserSchools = props.currentUserSchools.map(id => id.toString());
  const onChangeFilter = key => values => {
    const params = new URLSearchParams(window.location.search);

    params.delete(key);
    values.forEach(value => {
      params.append(key, value);
    });
    window.location.search = params.toString();
  };
  const onResetAllFilters = () => {
    const params = new URLSearchParams(window.location.search);

    params.delete(AUTO_SCHEDULING_FILTER_PARAM_KEY);
    params.delete(SCHOOL_FILTER_PARAM_KEY);
    window.location.search = params.toString();
  };
  const activeSchoolFilter = useMemo(() => {
    return new URLSearchParams(window.location.search).getAll(SCHOOL_FILTER_PARAM_KEY);
  }, []);
  const activeAutoSchedulingFilter = useMemo(() => {
    return new URLSearchParams(window.location.search).getAll(AUTO_SCHEDULING_FILTER_PARAM_KEY);
  }, []);

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <FilterDropdown
          activeFilterCount={activeSchoolFilter.length}
          className="w-[340px]"
          label="Schools"
          onChange={onChangeFilter(SCHOOL_FILTER_PARAM_KEY)}
          options={schoolSystems.map(schoolSystem => ({
            label: schoolSystem.name,
            options: schools.filter(school => school.schoolSystemId === schoolSystem.id).map(school => ({
              value: school.id,
              label: school.name,
              name: SCHOOL_FILTER_PARAM_KEY,
            })),
          }))}
          searchable
          searchInputPlaceholder="Search for schools or school systems..."
          values={activeSchoolFilter}
        />
        <FilterDropdown
          activeFilterCount={activeAutoSchedulingFilter.length}
          label="Auto-scheduling"
          onChange={onChangeFilter(AUTO_SCHEDULING_FILTER_PARAM_KEY)}
          options={[
            {
              value: "1",
              label: "Auto-scheduling on",
              name: AUTO_SCHEDULING_FILTER_PARAM_KEY,
            },
            {
              value: "0",
              label: "Auto-scheduling off",
              name: AUTO_SCHEDULING_FILTER_PARAM_KEY,
            },
          ]}
          type="radio"
          values={activeAutoSchedulingFilter}
        />
        {currentUserSchools.length > 0 ?
          <FilterShortcutCheckbox
            values={currentUserSchools}
            selectedValues={activeSchoolFilter}
            urlSearchKey={SCHOOL_FILTER_PARAM_KEY}
          />
        : null}
      </div>
      {activeSchoolFilter.length > 0 || activeAutoSchedulingFilter.length > 0 ? (
        <button className="button-secondary text-sm py-1.5" onClick={onResetAllFilters} type="button">
          Reset All
        </button>
      ) : null}
    </div>
  );
};
