import React, { createContext, useState } from "react";

export const ChangesListFiltersContext = createContext({});

export const ChangesListProvider = ({ children }) => {
  const [schools, setSchools] = useState([]);
  const [schoolSystems, setSchoolSystems] = useState([]);
  const [schoolsFilter, setSchoolsFilter] = useState([]);
  const [updateTypesFilter, setUpdateTypesFilter] = useState([]);

  return (
    <ChangesListFiltersContext.Provider
      value={{ schoolsFilter, updateTypesFilter, setSchoolsFilter, setUpdateTypesFilter, schools, setSchools,
        schoolSystems, setSchoolSystems }}>
      {children}
    </ChangesListFiltersContext.Provider>
  );
};
