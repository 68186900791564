import cx from "classnames";
import React from "react";

import { WeeklyView } from "../common/Calendar/";
import { isToday } from "date-fns";
import EventList from "./EventList";

export default () => (
  <WeeklyView hideHourHeading thClassNames="top-[144px] md:top-[150px] lg:top-[89px]">
    {(dateRange) => (
      <tr>
        {dateRange.map(date => (
          <td
            className={cx("align-top border-r border-l", {
              "bg-sky-50/60 -ml-[1px]": isToday(date),
            })}
            key={date.toISOString()}
          >
            <EventList date={date} />
          </td>
        ))}
      </tr>
    )}
  </WeeklyView>
);
