import { toast } from "react-toastify";
import React, { useState } from "react";

import { timeToCustomFormat } from "../../utils";
import { useCancelTimeOffMutation } from "./query";

const TutorTimeOff = ({ tutorTimeOff, setTutorTimeOffs }) => {
  const [selectedTimeOff, setSelectedTimeOff] = useState(null);
  const cancelMutation = useCancelTimeOffMutation(selectedTimeOff);

  const onCancel = (tutorTimeOff) => {
    if (!window.confirm("Are you sure?")) return;
    const toastId = toast.loading("Cancelling time off request...");
    setSelectedTimeOff(String(tutorTimeOff.id));
    cancelMutation.mutate(null, {
      onSuccess: () => {
        setTutorTimeOffs((prevTutorTimeOffs) =>
          prevTutorTimeOffs.map((timeOff) =>
            timeOff.id === tutorTimeOff.id ? { ...timeOff, status: "canceled" } : timeOff
          )
        );
        toast.update(toastId, {
          render: "Time off request cancelled.",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      },
      onError: () => {
        toast.update(toastId, {
          render: "There was a problem cancelling time off. Please try again.",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      },
    });
  };

  return (
    <tr data-testid={`admin-tutor-time-off-row-${tutorTimeOff.id}`}>
      <td
        className="py-4 px-3 text-sm text-zinc-500"
        data-testid={`admin-tutor-time-off-start-date-${tutorTimeOff.id}`}
      >
        {timeToCustomFormat(tutorTimeOff.start_date)}
      </td>
      <td
        className="py-4 pl-6 pr-3 text-sm text-zinc-500"
        data-testid={`admin-tutor-time-off-end-date-${tutorTimeOff.id}`}
      >
        {timeToCustomFormat(tutorTimeOff.end_date)}
      </td>
      <td
        className="py-4 pl-6 pr-3 text-sm text-zinc-500"
        data-testid={`admin-tutor-time-off-status-${tutorTimeOff.id}`}
      >
        {tutorTimeOff.status === "pending" || tutorTimeOff.status === "approved" ? (
          <button
            className="button-secondary h-8 text-brand-700 hover:ring-2 hover:ring-brand-500 hover:bg-white focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2 inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm sm:w-auto"
            onClick={() => onCancel(tutorTimeOff)}
            data-testid={`admin-tutor-time-off-cancel-button-${tutorTimeOff.id}`}
          >
            Cancel
          </button>
        ) : (
          <div className="pl-4">Canceled</div>
        )}
      </td>
    </tr>
  );
};

export default TutorTimeOff;
