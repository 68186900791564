import { useContext } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";

import { get, post } from "../../api";
import { SessionContext } from "./SessionItem";
import { useCurrentPagyPage } from "../common/Pagy";
import { FilterByDateContext } from ".";
import { useTable } from "../common/Table";

export const useUnclaimedSessionsQuery = () => {
  const page = useCurrentPagyPage();
  const { sortBy: sort, sortDirection: direction } = useTable();
  const {startDate, endDate, searchTerm: q} = useContext(FilterByDateContext);

  return useQuery({
    queryKey: ["unclaimed-sub-sessions", page, q, startDate, endDate, sort, direction],
    queryFn: async ({signal}) => {
      const response = await get("/admin/sub_session_requests/unclaimed", {
        params: {
          end: endDate,
          page,
          q,
          start: startDate,
          sort,
          direction
        },
        signal
      });
      return response.data;
    },
    keepPreviousData: true
  });
};

export const useTutorQuery = ({lastName}) => {
  return useQuery({
    queryKey: ["/admin/tutors", lastName],
    queryFn: async ({signal}) => {
      const response = await get("/admin/tutors", {
        params: {
          q: lastName ?? ""
        },
        signal
      });
      return response.data;
    }
  });
}

export const useTutorMutation = () => {
  const { selectedSession } = useContext(SessionContext);

  return useMutation({
    mutationFn: async ({ tutorId }) => {
      return post(`/admin/sub_session_requests/${selectedSession.id}/assign_tutor`, {
        tutor_id: tutorId,
        created_at: selectedSession.created_at,
        updated_at: selectedSession.updated_at,
        program_enrollment_id: selectedSession.program_enrollment_id,
        start_date: selectedSession.session_date
       });
    }
  });
}
