import { toast } from "react-toastify";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { Button } from "@Ignite-Reading/ui-kit";

import { useTutorTimeOffMutation } from "./query";
import SelectSessions from "./SelectSessions";

export const SelectSessionContext = createContext();

const AddTutorTimeOff = ({ setTutorTimeOffs }) => {
  const [reason, setReason] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [selectedSession, setSelectedSession] = useState([]);
  const queryMutation = useTutorTimeOffMutation({ startDate, endDate, reason, selectedSession });
  const value = useMemo(
    () => ({
      startDate,
      endDate,
      selectedSession,
      setSelectedSession,
    }),
    [startDate, endDate, selectedSession, setSelectedSession]
  );

  useEffect(() => {
    if (startDate !== endDate) {
      setSelectedSession([]);
    }
  }, [startDate, endDate, setSelectedSession]);

  const onSubmit = (event) => {
    event.preventDefault();
    if (startDate === "" || endDate === "") {
      toast.error("Start date and end date should be filled.");
      return;
    }
    if (startDate > endDate) {
      toast.error("Start date should be before end date.");
      return;
    }
    const toastId = toast.loading("Submitting time off request...");
    queryMutation.mutate(null, {
      onSuccess: ({ data }) => {
        setTutorTimeOffs(data.tutor_time_off);
        resetForm();
        toast.update(toastId, {
          render: "Time off request submitted.",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      },
      onError: () => {
        toast.update(toastId, {
          render: "There was a problem submitting time off. Please try again.",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      },
    });
  };

  const resetForm = () => {
    setReason("");
    setEndDate("");
    setStartDate("");
    setSelectedSession([]);
  };

  return (
    <SelectSessionContext.Provider value={value}>
      <div className="border-t border-zinc-200 flex align-center">
        <div className="pt-3.5 pr-3 text-sm font-medium text-gray-700 flex flex-col justify-around">
          <p>Start Date</p>
          <input
            type="date"
            autoFocus
            className="-ml-[1px] py-1.5 px-3 leading-none focus:ring-brand-500 focus:border-brand-500 shadow-sm text-sm border-zinc-300 rounded-md"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="pt-3.5 px-3 text-sm font-medium text-gray-700 flex flex-col justify-around">
          <p>End Date</p>
          <input
            type="date"
            className="py-1.5 px-3 leading-none focus:ring-brand-500 focus:border-brand-500 shadow-sm text-sm border-zinc-300 rounded-md"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div
          className="pt-3.5 px-3 text-sm font-medium text-gray-700 flex flex-col justify-around"
          data-testid="add-tutor-time-off-session-select"
        >
          <p>Session</p>
          <SelectSessions />
        </div>
      </div>
      <div className="pt-3 text-sm font-medium text-grey-700">Reason</div>
      <div className="pb-3">
        <textarea
          className="pt-3 px-3 text-sm text-zinc-500 focus:ring-brand-500 focus:border-brand-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          rows="3"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          data-testid="add-tutor-time-off-reason"
        />
      </div>
      <div className="pl-6 pr-3 text-sm text-zinc-500 flex justify-end">
        <Button
          onClick={onSubmit}
          className="button-primary"
          disabled={!startDate || !endDate || startDate > endDate}
          data-testid="add-tutor-time-off-button"
        >
          Add time off
        </Button>
      </div>
    </SelectSessionContext.Provider>
  );
};

export default AddTutorTimeOff;
