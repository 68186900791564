import debounce from "lodash/debounce";
import React, { useCallback, useContext, useEffect, useMemo } from "react";

import TutorTimeOffRequestsContext from "./context";

const SearchForm = () => {
  const { setSearchTerm } = useContext(TutorTimeOffRequestsContext);
  const onChange = useCallback((event) => {
    setSearchTerm(event.target.value);
  }, [setSearchTerm]);
  const debounced = useMemo(() => {
    return debounce(onChange, 340)
  }, [onChange]);

  useEffect(() => {
    return () => {
      debounced.cancel();
    };
  }, [debounced]);

  return (
    <div className="flex items-center">
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd" />
          </svg>
        </div>
        <input
          className="pl-10 py-[5px] pr-1 text-sm focus:ring-brand-500 focus:border-brand-500 shadow-sm sm:text-sm border-gray-300 rounded-lg w-[240px]"
          onChange={debounced}
          placeholder="Search by tutor name"
          type="search"
        />
      </div>
    </div>
  );
};

export default SearchForm;
