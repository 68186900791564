import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { parse } from "date-fns";
import isEmpty from "lodash/isEmpty";
import { useContext } from "react";

import { get, patch } from "../../api";
import { RailsPathsContext } from "../../contexts/RailsPathsContext";
import { DEFAULT_DATE_FORMAT } from "../../constants";
import { useCurrentPagyPage } from "../common/Pagy";
import { useTable } from "../common/Table";
import TutorTimeOffRequestsContext from "./context";

export const useTutorTimeOffQuery = ({ status }) => {
  const paths = useContext(RailsPathsContext);
  const { sortBy: sort, sortDirection: direction } = useTable();
  const page = useCurrentPagyPage();
  const {
    filterEndDate: end,
    filterStartDate: start,
    searchTerm: q,
  } = useContext(TutorTimeOffRequestsContext);

  return useQuery({
    queryKey: ["tutor-time-off", page, status, sort, direction, q, start, end],
    queryFn: async ({ signal }) => {
      const response = await get(paths.index, {
        params: {
          direction,
          end: isEmpty(end) ? undefined : end,
          page,
          q,
          sort,
          start: isEmpty(start) ? undefined : start,
          status,
        },
        signal,
      });
      const tutorTimeOff = response.data.tutorTimeOff.map((timeOff) => ({
        ...timeOff,
        endDate: parse(timeOff.endDate, DEFAULT_DATE_FORMAT, new Date()),
        startDate: parse(timeOff.startDate, DEFAULT_DATE_FORMAT, new Date()),
        createdAt: parse(timeOff.createdAt, DEFAULT_DATE_FORMAT, new Date()),
      }));

      return {
        tutorTimeOff,
        pagy: response.data.pagy,
      };
    },
    keepPreviousData: true,
  });
};

export const useTutorTimeOffDetailQuery = ({ uuid }) => {
  return useQuery({
    queryKey: ["tutor-time-off-detail", uuid],
    queryFn: async () => {
      const response = await get(`/admin/tutor_time_offs/${uuid}/details`);
      const timeOff = response.data;

      return timeOff;
    },
    keepPreviousData: true,
  });
};

export const useUpdateTimeOffMutation = ({ uri, status }) => {
  const queryClient = useQueryClient();
  const page = useCurrentPagyPage();
  const { sortBy: sort, sortDirection: direction } = useTable();
  const {
    filterEndDate: end,
    filterStartDate: start,
    searchTerm: q,
  } = useContext(TutorTimeOffRequestsContext);

  return useMutation({
    mutationFn: async ({ status }) => {
      return patch(uri, { status });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([
        "tutor-time-off",
        page,
        status,
        sort,
        direction,
        q,
        start,
        end,
      ]);
    },
  });
};
