import { format } from "date-fns";
import React, { useContext, useMemo } from "react";

import { SHORT_MONTH_AND_DAY } from "../../constants";
import { Modal, ModalContent, ModalTrigger } from "../common/Modal";
import { Tooltip, TooltipContent, TooltipTrigger } from "../common/Tooltip";
import TimeOffItemModal from "./TimeOffItemModal";
import TimeOffItemButtons from "./TimeOffItemButtons";
import HighlightSubstring from "../common/HighlightSubstring";
import TutorTimeOffRequestsContext from "./context";

const TimeOffItem = ({
  createdAt,
  currentDayCountForTimeOff,
  currentYearLabel,
  endDate,
  firstName,
  lastName,
  maxAllowedDayCountForTimeOff,
  reason,
  startDate,
  status,
  tutorUri,
  updateUri,
  uuid,
}) => {
  const { searchTerm } = useContext(TutorTimeOffRequestsContext);
  const searchTerms = useMemo(() => searchTerm.split(" "), [searchTerm]);

  return (
    <tr className="group">
      <td className="bg-white whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 group-last:rounded-bl-lg">
        <a className="text-brand-600 font-semibold hover:underline" href={tutorUri}>
          <HighlightSubstring value={firstName} q={searchTerms[0]} />
        </a>
      </td>
      <td className="bg-white whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
        <a className="text-brand-600 font-semibold hover:underline" href={tutorUri}>
          <HighlightSubstring
            value={lastName}
            q={searchTerms.length === 2 ? searchTerms[1] : searchTerms[0]}
          />
        </a>
      </td>
      <td className="bg-white whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 group-last:rounded-br-lg">
        <Modal>
          <Tooltip>
            <TooltipTrigger>
              <ModalTrigger>
                <button
                  className="button-as-link flex items-center space-x-1.5"
                  data-testid={`time-off-request-${uuid}-reason-tooltip`}
                >
                  <span>
                    {format(startDate, SHORT_MONTH_AND_DAY)} - {format(endDate, SHORT_MONTH_AND_DAY)}
                  </span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4">
                    <path fillRule="evenodd" d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0ZM9 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6.75 8a.75.75 0 0 0 0 1.5h.75v1.75a.75.75 0 0 0 1.5 0v-2.5A.75.75 0 0 0 8.25 8h-1.5Z" clipRule="evenodd" />
                  </svg>
                </button>
              </ModalTrigger>
            </TooltipTrigger>
            {reason ? (
              <TooltipContent className="max-w-[280px] text-left">
                <div className="space-y-1.5">
                  <h6 className="font-semibold">{firstName} {lastName} Time Off Notes</h6>
                  <p>{reason}</p>
                </div>
              </TooltipContent>
            ) : null}
          </Tooltip>
          <ModalContent className="w-full md:max-w-[700px] flex items-center justify-center">
            <TimeOffItemModal
              createdAt={createdAt}
              currentDayCountForTimeOff={currentDayCountForTimeOff}
              currentYearLabel={currentYearLabel}
              endDate={endDate}
              firstName={firstName}
              lastName={lastName}
              maxAllowedDayCountForTimeOff={maxAllowedDayCountForTimeOff}
              reason={reason}
              startDate={startDate}
              status={status}
              uri={updateUri}
              uuid={uuid}
            />
          </ModalContent>
        </Modal>
      </td>
      <td className="py-4 pl-3 pr-4 space-x-4 text-right">
        <TimeOffItemButtons
          firstName={firstName}
          lastName={lastName}
          status={status}
          uri={updateUri}
          uuid={uuid}
        />
      </td>
    </tr>
  );
};

export default TimeOffItem;
