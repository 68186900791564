import cx from "classnames"
import { twMerge } from "tailwind-merge";
import React from "react";

import { cancellationReason } from "../../constants";

const eventTitleToNiceNameMap = {
  [cancellationReason.NO_PROGRAM]: "No program",
  [cancellationReason.LAST_MINUTE]: "Cancellation",
  [cancellationReason.TIME_OFF]: "Time off"
};

export default ({ className, reason }) => (
  <div
    className={twMerge(
      "bg-white rounded-full uppercase font-medium text-[10px] mr-1 px-1.5 text-zinc-500 flex items-center whitespace-nowrap",
      className
    )}
  >
    <span className={cx("h-[10px] w-[10px] rounded-full mr-1",
      reason === cancellationReason.NO_PROGRAM ? "bg-orange-300" : "bg-orange-500"
    )} />
    {eventTitleToNiceNameMap[reason]}
  </div>
);
