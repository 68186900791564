import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";
import React from "react";

export default ({ selectedValues, urlSearchKey, values, testId }) => {
  const onChange = (event) => {
    const params = new URLSearchParams(window.location.search);

    params.delete(urlSearchKey);
    if (event.target.checked) {
      values.forEach((value) => {
        params.append(urlSearchKey, value);
      });
    }
    window.location.search = params.toString();
  };

  return (
    <div className="flex items-center mr-2">
      <input
        checked={isEqual(sortBy(values), sortBy(selectedValues))}
        className="mr-2 ml-1 cursor-pointer rounded-[4px] text-brand-500 border-gray-400 enabled:shadow-sm focus:ring-brand-500 focus:ring-2 focus:ring-offset-0 focus:checked:border-white"
        id="current-user-schools"
        onChange={onChange}
        type="checkbox"
        data-testid={testId}
      />
      <label className="cursor-pointer text-sm" htmlFor="current-user-schools">
        Show my schools only
      </label>
    </div>
  );
};
