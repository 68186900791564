import React from "react";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

import { useTutorMutation } from "./query";

const TutorItem = ({tutor}) => {
  const mutation = useTutorMutation();
  const queryClient = useQueryClient();

  const assignTutor = () => {
    const toastId = toast.loading("Assigning tutor to sub-session...");
    mutation.mutate({tutorId: tutor.id}, {
      onSuccess: () => {
        queryClient.invalidateQueries('unclaimed-sessions');
        toast.update(toastId, { render: "Tutor assigned to sub-session.", type: "success", isLoading: false, autoClose: 3000 });
      },
      onError: ({response}) => {
        toast.update(toastId, { render: response.data.errors, type: "errors", isLoading: false, autoClose: 3000 });
      }
    });
  };

  return (
    <tr data-testid={`admin-sub-session-request-tutor-${tutor.id}`}>
      <td className="whitespace-nowrap py-2.5 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{`${tutor.first_name} ${tutor.last_name}`}</td>
      <td className="relative whitespace-nowrap py-2.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <button
          className="cursor-pointer py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
          type="button"
          onClick={assignTutor}
          data-testid={`admin-sub-session-request-assign-button-${tutor.id}`}
        >
          Assign
        </button>
      </td>
    </tr>
  );
}

export default TutorItem;
