import { endOfDay, isWithinInterval, parse, startOfDay } from "date-fns";
import React, { useCallback, useContext, useMemo } from "react";

import { CurrentDateContext, CurrentViewContext } from "../../contexts/Calendar";
import { CalendarEventsContext } from "../../contexts/MasterCalendar";
import { CALENDAR_MONTLY_VIEW, CALENDAR_WEEKLY_VIEW, DEFAULT_DATE_FORMAT } from "../../constants";
import EventItem from "./EventItem";

const MONTHLY_VIEW_MAX_EVENTS_PER_DAY = 6;

export default ({ date }) => {
  const { calendarEvents } = useContext(CalendarEventsContext);
  const { setCurrentDate } = useContext(CurrentDateContext);
  const { currentView, setCurrentView } = useContext(CurrentViewContext);
  const dateIsWithinToday = useCallback(d => {
    return isWithinInterval(d, {
      start: startOfDay(date),
      end: endOfDay(date),
    });
  }, [date]);
  const eventsForThisDay = useMemo(() => {
    return calendarEvents.map(event => ({
      ...event,
      date: parse(event.date, DEFAULT_DATE_FORMAT, new Date())
    }))
      .filter(event => dateIsWithinToday(event.date));
  }, [calendarEvents, dateIsWithinToday]);
  const isMonthlyView = useMemo(() => currentView === CALENDAR_MONTLY_VIEW, [currentView]);
  const onClickMore = () => {
    setCurrentView(CALENDAR_WEEKLY_VIEW);
    setCurrentDate(date);
  };

  return (
    <div className="p-0.5">
      <ul className="flex flex-col space-y-0.5" key="list">
        {eventsForThisDay.slice(0, isMonthlyView ? MONTHLY_VIEW_MAX_EVENTS_PER_DAY : undefined).map(event => (
          <li key={event.uuid}>
            <EventItem
              classroomName={event.classroomName}
              date={event.date}
              gradeLevels={event.gradeLevels}
              schoolName={event.schoolName}
              schoolSystemName={event.schoolSystemName}
              times={event.times}
              title={event.title}
            />
          </li>
        ))}
      </ul>
      {isMonthlyView && eventsForThisDay.length > MONTHLY_VIEW_MAX_EVENTS_PER_DAY ? (
        <button
          className="p-0.5 rounded-full text-xs italic text-zinc-500 hover:underline"
          key="more"
          onClick={onClickMore}
          type="button"
        >
          + {eventsForThisDay.length - MONTHLY_VIEW_MAX_EVENTS_PER_DAY} more
        </button>
      ) : null}
    </div>
  );
}
