import React from "react";

import { Popover, PopoverContent, PopoverTrigger } from "../common/Popover";
import EventItemDetails from "./EventItemDetails";
import ReasonPill from "./ReasonPill";

export default ({ classroomName, date, gradeLevels, schoolName, schoolSystemName, times, title: reason }) => (
  <Popover offset={{ alignmentAxis: -20 }} placement="right-start">
    <PopoverTrigger>
      <div className="bg-zinc-200/70 hover:bg-zinc-200 rounded-full flex p-0.5 items-stretch text-xs min-h-[22px] cursor-pointer">
        <ReasonPill reason={reason} />
        <div className="text-zinc-500 font-medium truncate min-w-0 pr-0.5">
          {schoolName || schoolSystemName || "Ignite"}
        </div>
      </div>
    </PopoverTrigger>
    <PopoverContent>
      <EventItemDetails
        classroomName={classroomName}
        date={date}
        gradeLevels={gradeLevels}
        reason={reason}
        schoolName={schoolName}
        schoolSystemName={schoolSystemName}
        times={times}
      />
    </PopoverContent>
  </Popover>
);
