import cx from "classnames";
import has from "lodash/has";
import uniq from "lodash/uniq";
import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

import { Popover, PopoverContent, PopoverTrigger } from "../Popover";
import GroupedOptions from "./GroupedOptions";
import Options from "./Options";

export default ({
  activeFilterCount,
  className,
  label,
  onChange: onChangeCb,
  options,
  searchable,
  searchInputPlaceholder,
  type = "checkbox",
  values,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState(values || []);
  const [searchValue, setSearchValue] = useState("");
  const onChangeInput = (event) => {
    if (type === "radio") {
      setSelectedValues([event.target.value]);
    } else {
      setSelectedValues((prevValues) => {
        return event.target.checked
          ? uniq([...prevValues, event.target.value])
          : prevValues.filter((value) => value !== event.target.value);
      });
    }
  };
  const onApplyFilter = () => {
    onChangeCb(selectedValues);
    setOpen(false);
  };
  const onResetFilter = () => {
    setSelectedValues([]);
  };
  const onSelectAllValues = (values) => () => {
    setSelectedValues((prevValues) => [...prevValues, ...values]);
  };
  const onDeselectAllValues = (values) => () => {
    setSelectedValues((prevValues) =>
      prevValues.filter((prevValue) => !values.includes(prevValue))
    );
  };
  const onInvertSelection = (values) => () => {
    setSelectedValues((prevValues) => [
      ...prevValues.filter((prevValue) => !values.includes(prevValue)),
      ...values.filter((value) => !selectedValues.includes(value)),
    ]);
  };
  const onChangeSearchValue = (event) => {
    setSearchValue(event.target.value);
  };
  const OptionsComponent = options.every((option) => has(option, "options"))
    ? GroupedOptions
    : Options;

  return (
    <Popover onOpenChange={setOpen} open={open} placement="bottom-start" toggle>
      <PopoverTrigger>
        <button
          className={twMerge(
            cx("text-sm py-1.5 pr-2.5 mr-3 flex items-center whitespace-nowrap", {
              "button-primary bg-brand-500": activeFilterCount > 0,
              "button-secondary": activeFilterCount === 0,
              "enabled:shadow-inner": open,
              "bg-gray-100/75": open && activeFilterCount === 0,
              "bg-brand-700": open && activeFilterCount > 0,
            })
          )}
          data-testid={`filter-dropdown-btn`}
          type="button"
        >
          {label}
          {activeFilterCount > 0 ? (
            <span className="bg-brand-600 rounded-full text-xs ml-1 px-1 min-w-[24px]">
              {activeFilterCount}
            </span>
          ) : null}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={cx("ml-1 w-4 h-4 transition-transform", {
              "-rotate-180": open,
            })}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
          </svg>
        </button>
      </PopoverTrigger>
      <PopoverContent hideArrow hideCloseButton>
        <div
          className={twMerge(
            "min-w-[270px] max-w-[340px] flex flex-col max-h-[540px] rounded-lg overflow-hidden",
            className
          )}
        >
          {searchable ? (
            <div className="p-2 bg-zinc-50 border-b border-zinc-200/60">
              <input
                className="px-2 py-1 text-sm text-zinc-500 focus:ring-brand-500 focus:border-brand-500 shadow-sm border-gray-300 rounded-md w-full"
                onChange={onChangeSearchValue}
                placeholder={searchInputPlaceholder || "Search..."}
                type="text"
                value={searchValue}
                data-testid={`filter-search-input`}
              />
            </div>
          ) : null}
          <OptionsComponent
            options={options}
            onChange={onChangeInput}
            onSelectAll={onSelectAllValues}
            onSelectInverse={onInvertSelection}
            onSelectNone={onDeselectAllValues}
            searchValue={searchValue}
            selectedValues={selectedValues}
            type={type}
          />
          <div className="bg-zinc-50 border-t flex items-center px-3 py-2 rounded-b-lg">
            <button
              className="button-primary"
              onClick={onApplyFilter}
              type="button"
              data-testid="filter-apply-btn"
            >
              Apply
            </button>
            <button
              className="text-brand-500 text-sm px-4 py-2.5 leading-none hover:underline"
              onClick={onResetFilter}
              type="button"
              data-testid="filter-reset-btn"
            >
              Reset
            </button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
