import React, { useContext } from "react";

import FilterDropdown from "../common/FilterDropdown";
import { sessionChangeTriggerEventTypes } from "../../constants";
import { ChangesListFiltersContext } from "../../contexts/ChangesList";

export default () => {
  const {
    schools,
    schoolSystems,
    schoolsFilter,
    setSchoolsFilter,
    updateTypesFilter,
    setUpdateTypesFilter,
  } = useContext(ChangesListFiltersContext);

  const onChangeSchoolsFilter = (values) => {
    setSchoolsFilter(values);
  };
  const onChangeUpdateTypesFilter = (values) => {
    setUpdateTypesFilter(values);
  };

  return [
    <FilterDropdown
      activeFilterCount={schoolsFilter.length}
      className="w-[340px]"
      key="schools"
      label="Schools"
      onChange={onChangeSchoolsFilter}
      options={schoolSystems.map(schoolSystem => ({
        label: schoolSystem.name,
        options: schools.filter(school => school.schoolSystemId === schoolSystem.id).map(school => ({
          value: school.id,
          label: school.name,
        })),
      }))}
      searchable
      searchInputPlaceholder="Search for schools or school systems..."
    />,
    <FilterDropdown
      activeFilterCount={updateTypesFilter.length}
      className="w-[340px]"
      key="updateTypes"
      label="Update Types"
      onChange={onChangeUpdateTypesFilter}
      options={Object.values(sessionChangeTriggerEventTypes).map(value => ({
          value: value,
          label: value.split('_').map(type => type[0].toUpperCase() + type.substring(1)).join(' ')
        })).sort((a, b) => {
          if (a.value < b.value) {
            return -1;
          }
          if (a.value > b.value) {
            return 1;
          }
          return 0;
        })
      }
      searchable
      searchInputPlaceholder="Search for update types..."
    />,
  ];
}
