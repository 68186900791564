import cx from "classnames";
import React, { useId } from "react";

export default ({ onChange, options, selectedValues, type }) => {
  const id = useId();

  return (
    <ul className="overflow-scroll grow">
      {options.map(option => (
        <li className="flex items-center px-3 hover:bg-zinc-100/70" key={option.value}>
          <input
            checked={selectedValues.includes(option.value.toString())}
            className={cx({
              "checkbox": type === "checkbox",
              "radio": type === "radio",
            })}
            id={`${id}-${option.value}`}
            name={option.name}
            onChange={onChange}
            type={type}
            value={option.value}
          />
          <label className="cursor-pointer grow py-3 px-3 text-sm text-zinc-500" htmlFor={`${id}-${option.value}`}>
            {option.label}
          </label>
        </li>
      ))}
    </ul>
  );
}
