import React from "react";

import { MonthlyView } from "../common/Calendar";
import EventList from "./EventList";

export default () => (
  <MonthlyView>
    {(date) => <EventList date={date} />}
  </MonthlyView>
);
