import React, { useMemo, useState } from "react";

import DateRangePicker from "../common/DateRangePicker";
import { Tab, TabList, TabPanel, Tabs } from "../common/Tabs";
import { TableProvider } from "../common/Table";
import TimeOffList from "./TimeOffList";
import { APPROVED, DENIED, PENDING } from "../../constants/TutorTimeOffStatus"
import SearchForm from "./SearchForm";
import TutorTimeOffRequestsContext from "./context";

const TutorTimeOffRequests = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const value = useMemo(() => ({
    filterEndDate,
    filterStartDate,
    searchTerm,
    setSearchTerm,
  }), [filterEndDate, filterStartDate, searchTerm, setSearchTerm]);

  return [
    <div className="bg-white py-6" key="header">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900">Tutor time off request</h1>
          <p className="mt-2 text-sm text-gray-700">Review, approve or deny time off.</p>
        </div>
        <p className="flex items-centerZ text-zinc-500 text-xs mr-5 pt-0.5 whitespace-nowrap">
          <em>All times are Eastern Time</em>
        </p>
      </div>
    </div>,
    <TableProvider key="table-provider">
      <TutorTimeOffRequestsContext.Provider value={value}>
        <Tabs className="grow space-y-8" data-testid="time-off-request-tabs">
          <TabList className="flex">
            <Tab data-testid="time-off-request-to-review-tab-button">To review</Tab>
            <Tab data-testid="time-off-request-approved-tab-button">Approved</Tab>
            <Tab data-testid="time-off-request-denied-tab-button">Denied</Tab>
            <div className="grow justify-end flex items-center space-x-6">
              <div className="flex items-center space-x-3">
                <span className="text-gray-500 text-sm">
                  Filter by date:
                </span>
                <DateRangePicker
                  data-testid="admin-tutor-time-off-requests-filter"
                  endDate={filterEndDate}
                  onChangeEndDate={setFilterEndDate}
                  onChangeStartDate={setFilterStartDate}
                  startDate={filterStartDate}
                />
              </div>
              <SearchForm />
            </div>
          </TabList>
          <TabPanel className="grow flex flex-col">
            <TimeOffList
              data-testid="time-off-request-to-review-list"
              status={PENDING}
            />
          </TabPanel>
          <TabPanel className="grow flex flex-col">
            <TimeOffList
              data-testid="time-off-request-approved-list"
              status={APPROVED}
            />
          </TabPanel>
          <TabPanel className="grow flex flex-col">
            <TimeOffList
              data-testid="time-off-request-denied-list"
              status={DENIED}
            />
          </TabPanel>
        </Tabs>
      </TutorTimeOffRequestsContext.Provider>
    </TableProvider>
  ];
};

export default TutorTimeOffRequests;
