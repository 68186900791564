import { format } from "date-fns";
import React, { useContext } from "react";

import { CALENDAR_DAILY_VIEW, CALENDAR_MONTLY_VIEW, CALENDAR_WEEKLY_VIEW, MONTH_NAME_WITH_YEAR } from "../../constants";
import { CurrentDateContext } from "../../contexts/Calendar";
import { DateNavigator, ViewSwitcher } from "../common/Calendar";
import Filters from "./Filters";

export default () => {
  const { currentDate } = useContext(CurrentDateContext);

  return (
    <header className="bg-white border-b flex items-stretch justify-between py-7 px-8 sticky top-[55px] md:top-[61px] lg:top-0 z-40">
      <div className="flex items-center">
        <DateNavigator />
        <h1 className="text-2xl font-medium ml-3 mr-6 whitespace-nowrap">{format(currentDate, MONTH_NAME_WITH_YEAR)}</h1>
        <Filters />
      </div>
      <div className="flex items-center">
        <p className="flex items-center text-zinc-500 text-xs mr-5 pt-0.5 whitespace-nowrap">
          <em>All times are Eastern Time</em>
        </p>
        <ViewSwitcher views={[ CALENDAR_MONTLY_VIEW, CALENDAR_WEEKLY_VIEW, CALENDAR_DAILY_VIEW ]} />
      </div>
    </header>
  );
}
