import React, { createContext, useMemo, useState } from "react";

import AssignTutorModal from "./AssignTutorModal";
import { Modal, ModalContent, ModalTrigger } from "../common/Modal";
import { formatDateToMeridiem, timeToCustomFormat, timeToDateWithTime } from "../../utils";

export const SessionContext = createContext();

const SessionItem = ({ session }) => {
  const [selectedSession, setSelectedSession] = useState();

  const value = useMemo(
    () => ({
      selectedSession,
    }),
    [selectedSession]
  );

  return (
    <tr data-testid={`admin-sub-session-request-session-row-${session.id}`}>
      <td
        className="bg-white py-4 pl-4 pr-3 sm:pl-6 group-last:rounded-bl-lg"
        data-testid={`admin-sub-session-request-tutor-${session.id}`}
      >
        <a
          className="whitespace-nowrap text-sm text-brand-600 font-semibold hover:underline"
          href={`/admin/tutors/${session.tutor_id}/tutor_students`}
        >
          {`${session.first_name} ${session.last_name}`}
        </a>
      </td>
      <td
        className="bg-white whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 group-last:rounded-bl-lg"
        data-testid={`admin-sub-session-request-session-date-${session.id}`}
      >
        {timeToCustomFormat(session.session_date)}
      </td>
      <td
        className="bg-white whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 group-last:rounded-bl-lg"
        data-testid={`admin-sub-session-request-session-time-${session.id}`}
      >
        {formatDateToMeridiem({ date: session.start_time, timeZone: session.time_zone })}
      </td>
      <td
        className="bg-white py-4 pl-4 pr-3 sm:pl-6 group-last:rounded-bl-lg"
        data-testid={`admin-sub-session-request-student-${session.id}`}
      >
        <a
          className="whitespace-nowrap text-sm text-brand-600 font-semibold hover:underline"
          href={`/admin/students/${session.student_id}/edit`}
        >
          {`${session.student_first_name} ${session.student_last_name}`}
        </a>
      </td>
      <td
        className="bg-white whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 group-last:rounded-bl-lg"
        data-testid={`admin-sub-session-request-requested-date-${session.id}`}
      >
        {timeToDateWithTime(session.created_at)}
      </td>
      <td className="bg-white whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 group-last:rounded-bl-lg">
        Unclaimed
      </td>
      <td
        className="bg-white whitespace-nowrap py-2.5 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 group-last:rounded-bl-lg"
        data-testid={`admin-sub-session-request-modal-${session.id}`}
      >
        <SessionContext.Provider value={value}>
          <Modal>
            <ModalTrigger>
              <button
                className="button-primary"
                type="button"
                onClick={() => setSelectedSession(session)}
                data-testid={`admin-sub-session-request-assign-tutor-button-${session.id}`}
              >
                Assign Tutor
              </button>
            </ModalTrigger>
            <ModalContent className="w-full md:max-w-[700px] flex items-center justify-center">
              <AssignTutorModal />
            </ModalContent>
          </Modal>
        </SessionContext.Provider>
      </td>
    </tr>
  );
};

export default SessionItem;
