import React, { useState } from "react";

import AddTutorTimeOff from "./AddTutorTimeOff";
import TutorTimeOff from "./TutorTimeOff";

const AdminTutorTimeOff = ({ tutor, tutorTimeOffs: initialTutorTimeOffs }) => {
  const [tutorTimeOffs, setTutorTimeOffs] = useState(initialTutorTimeOffs);

  return (
    <div className="w-fit">
      <div className="mt-10">
        <div className="sm:flex sm:justify-between">
          <div className="space-y-1">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Time off for {`${tutor.first_name} ${tutor.last_name}`}
            </h3>
            <p className="max-w-2xl text-sm text-gray-500">
              {`${tutor.first_name} ${tutor.last_name}`}&apos;s time off requests that can be
              cancelled or have been cancelled.
            </p>
          </div>
        </div>
      </div>
      <table className="mt-8 flex flex-col w-fit" data-testid="admin-tutor-time-off-table">
        <thead>
          <tr>
            <th
              className="py-3.5 px-3 text-left text-sm font-medium text-zinc-700 border-b border-zinc-200"
              scope="col"
            >
              Start Date
            </th>
            <th
              className="py-3.5 pl-6 pr-3 text-left text-sm font-medium text-zinc-700 border-b border-zinc-200"
              scope="col"
            >
              End Date
            </th>
            <th
              className="py-3.5 pl-10 pr-3 text-left text-sm font-medium text-zinc-700 border-b border-zinc-200"
              scope="col"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {tutorTimeOffs.map((tutorTimeOff) => (
            <TutorTimeOff
              key={tutorTimeOff.uuid}
              tutorTimeOff={tutorTimeOff}
              setTutorTimeOffs={setTutorTimeOffs}
            />
          ))}
        </tbody>
      </table>
      <AddTutorTimeOff setTutorTimeOffs={setTutorTimeOffs} />
    </div>
  );
};

export default AdminTutorTimeOff;
