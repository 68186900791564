import React from "react";
import { twMerge } from "tailwind-merge";

const HighlightSubstring = ({ className, q, value }) => {
  const regex = new RegExp(`${q || "\b"}`, "gi");
  const matchedCharIndex = [...value.matchAll(regex)].map(match => {
    return Array.from({ length: match[0].length }, (_, index) => {
      return match.index + index;
    });
  }).flat();

  return value.split("").map((char, index) => {
    return matchedCharIndex.includes(index) ? (
      <span className={twMerge("bg-yellow-300", className)} key={index}>
        {char}
      </span>
    ) : char;
  });
}

export default HighlightSubstring;
