import Select from "react-select";
import React, { useContext } from "react";

import { useTutoringBlocksQuery } from "./query";
import { SelectSessionContext } from "./AddTutorTimeOff";

const SelectSessions = () => {
  const { startDate, endDate, selectedSession, setSelectedSession } =
    useContext(SelectSessionContext);
  const tutoringBlockQuery = useTutoringBlocksQuery(startDate === endDate ? startDate : "");

  const sessions =
    tutoringBlockQuery.data?.sessions && startDate === endDate
      ? tutoringBlockQuery.data?.sessions
      : [];

  return (
    <Select
      classNames={{
        control: (state) =>
          state.isFocused
            ? "mt-1 block !min-w-[12rem] !border-brand-500 !border-2 !shadow-sm sm:text-sm  rounded-md"
            : "mt-1 min-w-[12rem]",
      }}
      placeholder="Select Sessions"
      isDisabled={sessions.length === 0}
      isMulti
      options={sessions.map((session) => ({ value: session, label: session }))}
      value={selectedSession}
      onChange={setSelectedSession}
    />
  );
};

export default SelectSessions;
