import React, { createContext, useMemo, useState } from "react";
import { format, utcToZonedTime } from "date-fns-tz";

import SearchForm from "./SearchForm";
import SessionList from "./SessionList";
import { TableProvider } from "../common/Table";

export const FilterByDateContext = createContext({});

const SubSessionRequests = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const value = useMemo(
    () => ({
      startDate,
      endDate,
      searchTerm,
      setSearchTerm,
    }),
    [startDate, endDate, searchTerm, setSearchTerm]
  );

  const minStartDate = useMemo(() => {
    const now = new Date();
    const easternTimeZone = "America/New_York";

    return format(utcToZonedTime(now, easternTimeZone), "yyyy-MM-dd", {
      timeZone: easternTimeZone,
    });
  }, []);

  return (
    <TableProvider key="table-provider">
      <FilterByDateContext.Provider value={value}>
        <div className="mt-6">
          <div className="sm:flex sm:item-center sm:justify-between">
            <div className="space-y-1">
              <h3 className="text-2xl font-semibold text-gray-900">Unclaimed Sessions</h3>
              <p className="mt-2 text-sm text-gray-700">
                Manage all active unclaimed substitute requests.
              </p>
            </div>
            <p className="flex items-center text-zinc-500 text-xs mr-5 pt-0.5 whitespace-nowrap">
              <em>All times are Eastern Time</em>
            </p>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex justify-between">
            <div className="grow flex items-center space-x-3">
              <span className="text-gray-500 text-sm">Filter by date:</span>
              <div className="flex items-center space-x-3">
                <input
                  className="border-gray-300 py-[5px] rounded-lg focus:ring-brand-500 focus:border-brand-500 shadow-sm sm:text-sm"
                  min={minStartDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  type="date"
                  value={startDate}
                />
                <span className="text-gray-400">-</span>
                <input
                  className="border-gray-300 py-[5px] rounded-lg focus:ring-brand-500 focus:border-brand-500 shadow-sm sm:text-sm disabled:bg-gray-50 disabled:text-gray-400"
                  min={startDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  type="date"
                  value={endDate}
                  disabled={!startDate}
                />
              </div>
            </div>
            <SearchForm />
          </div>
        </div>
        <div className="mt-6">
          <SessionList data-testid="unclaimed-sessions-list" />
        </div>
      </FilterByDateContext.Provider>
    </TableProvider>
  );
};

export default SubSessionRequests;
