import React, { createContext, useState } from "react";

export const CalendarEventsContext = createContext({});
export const FilterContext = createContext({});
export const SchoolsContext = createContext({});
export const SchoolSystemsContext = createContext({});

export const MasterCalendarProvider = ({ children }) => {
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [nonInstructionalDayReasonsFilter, setNonInstructionalDayReasonsFilter] = useState([]);
  const [schools, setSchools] = useState([]);
  const [schoolsFilter, setSchoolsFilter] = useState([]);
  const [schoolSystems, setSchoolSystems] = useState([]);

  return (
    <CalendarEventsContext.Provider value={{ calendarEvents, setCalendarEvents }}>
      <SchoolsContext.Provider value={{ schools, setSchools }}>
        <SchoolSystemsContext.Provider value={{ schoolSystems, setSchoolSystems }}>
          <FilterContext.Provider
            value={{
              nonInstructionalDayReasonsFilter,
              schoolsFilter,
              setNonInstructionalDayReasonsFilter,
              setSchoolsFilter,
            }}
          >
            {children}
          </FilterContext.Provider>
        </SchoolSystemsContext.Provider>
      </SchoolsContext.Provider>
    </CalendarEventsContext.Provider>
  );
};
