import React, { useContext } from "react";

import SessionItem from "./SessionItem";
import { Table } from "../common/Table";
import { useUnclaimedSessionsQuery } from "./query";
import { FilterByDateContext } from ".";

const SessionList = ({ "data-testid": dataTestid }) => {
  const { searchTerm } = useContext(FilterByDateContext);
  const query = useUnclaimedSessionsQuery();

  return (
    <Table
      columns={[
        {
          id: "requested_by",
          label: "Requested By",
          isSortable: true,
        },
        {
          id: "session_date",
          label: "Session Date",
          isSortable: true,
        },
        {
          id: "session_time",
          label: "Session Time",
          isSortable: false,
        },
        {
          id: "student",
          label: "Student",
          isSortable: true,
        },
        {
          id: "date_requested",
          label: "Date Requested",
          isSortable: true,
        },
        {
          id: "status",
          label: "Status",
          isSortable: false,
        },
        {
          id: "assign",
          label: "",
          isSortable: false,
        },
      ]}
      data-testid={dataTestid}
      query={query}
      colSpan={7}
    >
      {(data) => data.unclaimedSessions.length > 0 ? data.unclaimedSessions.map(session => (
        <SessionItem key={session.uuid} session={session} />
      )) : (
        <tr>
          <td
            className="bg-white p-4 text-sm text-gray-900 sm:pl-6 rounded-b-lg"
            colSpan={7}
          >
            <p className="flex items-center justify-center grow text-gray-500 min-h-[120px]">
              {searchTerm
                ? `There are no results for: ${searchTerm}`
                : "There are no unclaimed sessions."
              }
            </p>
          </td>
        </tr>
      )}
    </Table>
  );
};

export default SessionList;
