import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect } from "react";

import { get } from "../../api";
import { PagyContext, PagyNavigation, useCurrentPagyPage } from "../common/Pagy";
import ChangesItem from "./ChangesItem";
import { ChangesQueueContext } from "../../contexts/SessionChangesQueue";
import { ChangesListFiltersContext } from "../../contexts/ChangesList";
import { PAGY_UPPER_PAGE_BOUND } from "../../constants";

export default ({ done }) => {
  const { changes, setChanges } = useContext(ChangesQueueContext);
  const { setPagy, setUpperPageBound, setLowerPageBound } = useContext(PagyContext);
  const currentPage = useCurrentPagyPage();
  const {
    schoolsFilter,
    setSchools,
    setSchoolSystems,
    updateTypesFilter,
  } = useContext(ChangesListFiltersContext);
  const changesQuery = useQuery({
    queryKey: ["session-changes", done, currentPage, schoolsFilter, updateTypesFilter],
    queryFn: async ({ signal }) => {
      const { data } = await get("session_changes.json", {
        params: {
          done,
          page: currentPage,
          schools: schoolsFilter,
          update_types: updateTypesFilter,
        },
        signal,
      });

      return data;
    },
  });

  useEffect(() => {
    if (changesQuery.isFetched) {
      const { changes, schoolSystems, schools } = changesQuery.data;

      setChanges(changes);
      setSchoolSystems(schoolSystems);
      setSchools(schools);
    }
  }, [
    changesQuery.data,
    changesQuery.isFetched,
    setChanges,
    setSchoolSystems,
    setSchools,
  ]);

  useEffect(() => {
    setUpperPageBound(PAGY_UPPER_PAGE_BOUND);
    setLowerPageBound(0);
    setPagy((prevPagy) => ({ ...prevPagy, page: 1 }));
  }, [schoolsFilter, updateTypesFilter, setLowerPageBound, setUpperPageBound, setPagy])

  return changesQuery.isLoading ? (
    <div className="grow flex flex-col items-center justify-center bg-gray-50 rounded-lg py-10 px-4 mt-4">
      <p className="text-gray-500">Loading...</p>
    </div>
  ) : changesQuery.data.changes.length > 0 ? [
      <ul className="flex flex-col gap-5 pt-5 mb-6" key="list">
        {changes.map(change => (
          <li key={change.uuid}>
            <ChangesItem
              change_type={change.change_type}
              diffs={change.diffs}
              done={change.done}
              recurring={change.recurring}
              uuid={change.uuid}
            />
          </li>
        ))}
      </ul>,
      <PagyNavigation key="pagy" query={changesQuery} />,
  ] : (
    <div className="grow flex flex-col items-center justify-center bg-gray-50 rounded-lg py-10 px-4 mt-4">
      <p className="text-gray-500">There are no changes.</p>
    </div>
  );
};
