import React, { createContext, useState } from "react";

export const ChangesQueueContext = createContext({});

export const SessionChangesQueueProvider = ({ children }) => {
  const [changes, setChanges] = useState([]);

  return (
    <ChangesQueueContext.Provider value={{ changes, setChanges }}>
      {children}
    </ChangesQueueContext.Provider>
  );
};
