import React from "react";

import { DailyView } from "../common/Calendar";
import EventList from "./EventList";

export default () => (
  <DailyView>
    {(date) => <EventList date={date} />}
  </DailyView>
);
